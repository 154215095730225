import persistedState from '../config/configureStore';

import {setPortalSettings} from '../reducers/SystemData'

export default class SettingsFetcher {
    constructor(completionHandler, failureHandler) {
        this.settingsKeys = [
            "aws_host_setting",
            "default_map_latitude_setting",
            "default_map_longitude_setting",
            "default_map_zoom",
            "map_box_api_key_setting",
            "default_project_author",
            "default_project_year",
            "default_pagination_page_size"
        ]
        this.successHandler = completionHandler;
        this.failureHandler = failureHandler;
        this.settingsEndpoint = process.env.REACT_APP_BASE_HOST_URL + "/settings";
    }

    fetchSettings() {
        fetch(this.settingsEndpoint)
            .then(response => response.json())
            .then(response => {
                if (response._embedded !== undefined && response._embedded.settingResources !== undefined) {
                    let rawSettings = response._embedded.settingResources;
                    if (this.areAllKeysPresentInResponse(rawSettings)) {
                        this.updateStateWithSettings(rawSettings);
                        this.successHandler(this.extractSettings(rawSettings));
                    } else {
                        this.failureHandler();
                    }
                } else {
                    this.successHandler([]);
                }
            })
            .catch(error => {
                this.failureHandler();
                console.log(error);
            });
    }

    extractSettings(fetchedSettings) {
        return {
            awsHost: this.extractSingleSetting('aws_host_setting', fetchedSettings),
            mapBoxApiKey: this.extractSingleSetting('map_box_api_key_setting', fetchedSettings),
            defaultMapLatitude: this.extractSingleSetting('default_map_latitude_setting', fetchedSettings),
            defaultMapLongitude: this.extractSingleSetting('default_map_longitude_setting', fetchedSettings),
            defaultMapZoom: this.extractSingleSetting('default_map_zoom', fetchedSettings),
            defaultProjectAuthor: this.extractSingleSetting('default_project_author', fetchedSettings),
            defaultProjectValidityYear: this.extractSingleSetting('default_project_year', fetchedSettings),
            defaultPaginationPageSize: this.extractSingleSetting('default_pagination_page_size', fetchedSettings)
        };
    }

    extractSingleSetting(settingKey, fetchedSettings) {
        return fetchedSettings.filter(fetchedSetting => (fetchedSetting.setting.key === settingKey))[0].setting.value
    }

    areAllKeysPresentInResponse(fetchedSettings) {
        var areAllKeysPresent = true;
        var fetchedSettingsKeys = fetchedSettings.map(fetchedSetting => {
            return fetchedSetting.setting.key;
        });
        this.settingsKeys.forEach(settingKey => {
            if (!fetchedSettingsKeys.includes(settingKey)) {
                areAllKeysPresent = false;
            }
        });
        return areAllKeysPresent;
    }

    updateStateWithSettings(fetchedSettings) {
        let portalSettings = {
            'aws_host_setting': this.extractSingleSetting('aws_host_setting', fetchedSettings),
            'map_box_api_key_setting': this.extractSingleSetting('map_box_api_key_setting', fetchedSettings),
            'default_map_latitude_setting': this.extractSingleSetting('default_map_latitude_setting', fetchedSettings),
            'default_map_longitude_setting': this.extractSingleSetting('default_map_longitude_setting', fetchedSettings),
            'default_map_zoom': this.extractSingleSetting('default_map_zoom', fetchedSettings),
            'default_project_author': this.extractSingleSetting('default_project_author', fetchedSettings),
            'default_project_year': this.extractSingleSetting('default_project_year', fetchedSettings),
            'default_pagination_page_size': this.extractSingleSetting('default_pagination_page_size', fetchedSettings),
        }
        persistedState.dispatch(setPortalSettings(portalSettings));
    }

    areAllSettingsPresentInPersistedState() {
        var result = true;
        let state = persistedState.getState();
        this.settingsKeys.forEach(settingKey => {
            if (state.SystemData.portalSettings[settingKey] === null 
                || state.SystemData.portalSettings[settingKey]  === undefined) {
                result = false;
            }
        });
        return result;
    }
}