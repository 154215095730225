import React from "react";
import './Login.css';
import {withRouter} from 'react-router-dom';

import {connect} from 'react-redux';

import {
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Progress,
  Container,
  Row,
  Col,
  Badge
} from "reactstrap";

import Button from '@material-ui/core/Button';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope, faKey, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

import Authentication from '../../../Auth/Authentication/Authentication'
import SettingsFetcher from '../../../WebService/SettingsFetcher';

import errorStrings from '../../../assets/localization/errors';
import loginStrings from '../../../assets/localization/login';

class Login extends React.Component {
  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
       this.loginButtonClickedHandler();
    }
}

  state = {
    loginData: {
      username: "",
      password: ""
    },
    shouldProgressBarBeVisible: false,
    shouldErrorMessageBeVisible: false,
    errorMessage: ""
  }

  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.loginButtonClickedHandler = this.loginButtonClickedHandler.bind(this);
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  shouldRouteToResetPasswordScreen() {
    return (this.props.loggedInUserData !== undefined 
      && this.props.loggedInUserData.shouldResetPassword !== undefined 
      && this.props.loggedInUserData.shouldResetPassword);
  }

  loginButtonClickedHandler() {
    this.hideErrorBadge();
    this.showProgressBar();
    Authentication.login(this.state.loginData.username, this.state.loginData.password, 
      () => {
        new SettingsFetcher(() => {
          this.hideProgressBar();
          if (this.shouldRouteToResetPasswordScreen()) {
            this.props.history.push("/reset-password");
          } else {
            this.props.history.push("/dashboards/basic");
          }
        }, () => {
          this.hideProgressBar();
          this.setErrorBadgeMessage(errorStrings.login101Error)
          this.showErrorBadge();
        }).fetchSettings();
    }, (errorMessage) => {
      this.hideProgressBar();
      this.setErrorBadgeMessage(errorMessage);
      this.showErrorBadge();
    });
  }  

  // -- STATE OPS --

  showProgressBar() {
    this.setState({
      shouldProgressBarBeVisible: true
    });
  }

  hideProgressBar() {
    this.setState({
      shouldProgressBarBeVisible: false
    });
  }

  showErrorBadge() {
    this.setState({
      shouldErrorMessageBeVisible: true
    });
  }

  hideErrorBadge() {
    this.setState({
      shouldErrorMessageBeVisible: false
    });
  }

  setErrorBadgeMessage(message) {
    this.setState({
      errorMessage: message
    });
  }

  // -- FORM ACTIONS --

  handleInputChange(event) {
    const target = event.target;
    const name = target.name; 
    const value = target.value;

    var loginData = this.state.loginData;
    loginData[name] = value;

    this.setState({
      loginData: loginData
    });
  }

  // -- JSX --
  render() {
    return (
      <>
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0">
                    <CardHeader>
                      { this.state.shouldErrorMessageBeVisible &&
                        <Badge  color="danger">
                          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2"/>
                          {this.state.errorMessage}
                        </Badge>
                      }
                      { !this.state.shouldErrorMessageBeVisible &&
                        <span>
                          {loginStrings.welcomeMessage}
                        </span>
                      }
                    </CardHeader>
                    <CardBody color="danger">
                      <Form role="form" onKeyDown={this._handleKeyDown}>
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <FontAwesomeIcon icon={faEnvelope}/>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input 
                              placeholder="Email" 
                              name="username"
                              onChange={this.handleInputChange}
                              value={this.state.loginData.username} 
                              type="email" />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <FontAwesomeIcon icon={faKey}/>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder={loginStrings.passwordPlaceholder}
                              type="password"
                              name="password"
                              onChange={this.handleInputChange}
                              value={this.state.loginData.password}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup> 
                        <div className="text-center">
                          { !this.state.shouldProgressBarBeVisible &&
                            <Button
                              className="my-4"
                              variant="contained"
                              color="primary"
                              type="button"
                              onClick={() => this.loginButtonClickedHandler()}
                              disabled={this.state.shouldProgressBarBeVisible} >
                              {loginStrings.loginButton}
                            </Button>
                          }
                          { this.state.shouldProgressBarBeVisible &&
                            <Progress className="mb-3" animated color="info" value="100" />
                          }
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUserData: state.SystemData.loggedInUserData
});

const mapDispatchToProps = dispatch => ({ });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
