import Authentication from '../Authentication/Authentication'
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, pathname, ...rest}) => {
    return (
      <Route
        {...rest}
        render={props => {
          if (Authentication.isAuthenticated()) {
            return <Component {...props} />;
          } else {
            return (
              <Redirect to={
                {
                  pathname: pathname,
                  state: {
                    from: props.location
                  }
                }
              } />
            );
          }
        }}
      />
    );
  }

  export default ProtectedRoute;