import persistedState from '../config/configureStore'

export default class UserFetcher {
    constructor(completionHandler, failureHandler) {
        this.successHandler = completionHandler;
        this.failureHandler = failureHandler;
        this.userEndpoint = process.env.REACT_APP_BASE_HOST_URL + "/users";
    }

    fetchUsers() {
        fetch(this.userEndpoint, {
            headers: {
                'Authorization': 'Bearer ' + persistedState.getState().SystemData.authData['access_token']
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.content !== undefined) {
                    let result = response.content.map(stats => stats.appUserDTO);
                    this.successHandler(result);
                } else {
                    this.successHandler([]);
                }
            })
            .catch(error => {
                this.failureHandler();
                console.log(error);
            });
    }

    fetchCurrentUserInfo(callback) {
        fetch(this.userEndpoint + "/current", {
            headers: {
                'Authorization': 'Bearer ' + persistedState.getState().SystemData.authData['access_token']
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.appUserDTO !== undefined) {
                    callback(response.appUserDTO);
                }
            })
            .catch(error => {
                this.failureHandler();
                console.log(error);
            });
    }
}