import React from "react";

import {withRouter} from 'react-router-dom';

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CardHeader
} from "reactstrap";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faKey} from '@fortawesome/free-solid-svg-icons';

import UserUpdater from "../../../WebService/UserUpdater";
import loginStrings from '../../../assets/localization/login';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        newPassword: ""
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.userUpdater = new UserUpdater(() => {}, () => {});
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  // -- FORM ACTIONS --

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;

    this.setState({
      newPassword: value
    });
  }

  // -- JSX --
  render() {
    return (
      <>
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0">
                      <CardHeader>
                      {loginStrings.resetPasswordMessage}
                      </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <Form role="form">
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <FontAwesomeIcon icon={faKey}/>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Hasło"
                              type="password"
                              name="password"
                              onChange={this.handleInputChange}
                              value={this.state.newPassword}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup> 
                        <div className="text-center">
                          <Button
                            className="my-4"
                            color="primary"
                            type="button"
                            onClick={() => {
                                this.userUpdater.changeOwnPassword(this.state.newPassword, () => {
                                    this.userUpdater.changeResetPasswordStatus(false, () => {
                                        this.props.history.push("/dashboards/basic");
                                    })
                                });
                            }}>
                            {loginStrings.resetPasswordButton}
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

export default withRouter(ResetPassword);
