import {Route, Redirect} from 'react-router-dom';
import React, {Suspense, lazy, Fragment} from 'react';
import infoStrings from '../../assets/localization/info';

import {
    ToastContainer,
} from 'react-toastify';

const Dashboards = lazy(() => import('../../Pages/Dashboards'));
const Projects = lazy(() => import('../../Pages/Projects'));
const Inbox = lazy(() => import('../../Pages/Inbox'));
const Users = lazy(() => import('../../Pages/Users'));
const Settings = lazy(() => import('../../Pages/Settings'));



const AppMain = () => {
    return (
        <Fragment>

            {/* Projects */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-5">
                            {infoStrings.contentToolLoadingDescription}
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/projects" component={Projects}/>
            </Suspense>

            {/* Dashboards */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            {infoStrings.dashboardLoadingDescription}
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/dashboards" component={Dashboards}/>
            </Suspense>

            {/* Inbox */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-5">
                            {infoStrings.inboxLoadingDescription}
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/inbox" component={Inbox}/>
            </Suspense>

            {/* User Management */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-5">
                            {infoStrings.userManagementToolLoadingDescription}
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/users" component={Users}/>
            </Suspense>

            {/* Settings */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-5">
                            {infoStrings.settingsToolLoadingDescription}
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/settings" component={Settings}/>
            </Suspense>

            <Route exact path="/" render={() => (
                <Redirect to="/dashboards/basic"/>
            )}/>
            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;