import LocalizedStrings from 'react-localization';

const errorStrings = new LocalizedStrings({
    pl: {
        statsDownloadError: "Błąd pobierania statystyk projektów.",
        mailDownloadError: "Błąd pobierania poczty.",
        projectLabelDownloadError: "Błąd pobierania etykiet.",
        projectsDownloadError: "Błąd pobierania projektów.",
        dataUpdateError: "Wystąpił błąd podczas aktualizacji danych.",
        login101Error: "Błąd logowania [#101]. Prosimy spróbować ponownie.",
        malformedDataError: "Wystąpił błąd. Sprawdź poprawność wprowadzonych danych.",
        thumbnailLoadError: "Wystąpił błąd podczas ładowania miniaturki.",
        settingsFetchError: "Wystąpił błąd pobierania ustawień portalu.",
        fileLoadError: "Wystąpił błąd podczas ładowania pliku.",
        fileUploadError: "Wystąpił błąd podczas wysyłania pliku.",
        revisionDownloadError: "Błąd pobierania historii zmian.",
        accountUpdateError: "Błąd aktualizacji informacji o użytkownikach.",
        usersDownloadError: "Błąd pobierania użytkowników."
    },
    en: {
        statsDownloadError: "Błąd pobierania statystyk projektów.",
        mailDownloadError: "Błąd pobierania poczty.",
        projectLabelDownloadError: "Błąd pobierania etykiet.",
        projectsDownloadError: "Błąd pobierania projektów.",
        dataUpdateError: "Wystąpił błąd podczas aktualizacji danych.",
        login101Error: "Błąd logowania [#101]. Prosimy spróbować ponownie.",
        malformedDataError: "Wystąpił błąd. Sprawdź poprawność wprowadzonych danych.",
        thumbnailLoadError: "Wystąpił błąd podczas ładowania miniaturki.",
        settingsFetchError: "Wystąpił błąd pobierania ustawień portalu.",
        fileLoadError: "Wystąpił błąd podczas ładowania pliku.",
        fileUploadError: "Wystąpił błąd podczas wysyłania pliku.",
        revisionDownloadError: "Błąd pobierania historii zmian.",
        accountUpdateError: "Błąd aktualizacji informacji o użytkownikach.",
        usersDownloadError: "Błąd pobierania użytkowników."
    }
});

export default errorStrings;