import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

import { HashRouter, Route, Switch } from 'react-router-dom';
import './assets/base.css';
import Main from './Pages/Main';
import Login from './Pages/Login/Login/';
import ProtectedRoute from './Auth/ProtectedRoute/'
import persistedState from './config/configureStore';
import { Provider } from 'react-redux';
import ResetPassword from './Pages/Login/ResetPassword';

const rootElement = document.getElementById('root');

ReactDOM.render(
  <Provider store={persistedState}>
    <HashRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <ProtectedRoute path="*" pathname="/login" component={Main} />
      </Switch>
    </HashRouter>
  </Provider>,
  rootElement
);

unregister();

registerServiceWorker();
