import { createStore, combineReducers } from 'redux';
import reducers from '../reducers';
import _ from 'lodash';

export const persistedState = loadState();
export const store = configureStore();

function configureStore() {
  return createStore(
    combineReducers({
      ...reducers
    }),
    loadState(),
  );
}

store.subscribe(_.throttle(() => {
  saveState(store.getState());
}, 1000));

function loadState() {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch(err) {
    console.log(err);
    return undefined;
  }
}; 

function saveState(state) {
  try {
    localStorage.setItem('state', JSON.stringify(state));
  } catch(err) {
    console.log(err);
  }
};

export default store;