export const SET_LOGGED_IN_USER_DATA = 'SET_LOGGED_IN_USER_DATA';

export const SET_PORTAL_SETTINGS = 'SET_PORTAL_SETTINGS';

export const SET_AUTH_DATA = 'SET_AUTH_DATA';

export const setLoggedInUserData = loggedInUserData => ({
    type: SET_LOGGED_IN_USER_DATA,
    loggedInUserData
});

export const setPortalSettings = portalSettings => ({
    type: SET_PORTAL_SETTINGS,
    portalSettings
});

export const setAuthData = authData => ({
    type: SET_AUTH_DATA,
    authData
});

export default function reducer(state = {
    loggedInUserData: {},
    portalSettings: {},
    authData: {}
}, action) {
    switch (action.type) {
        case SET_LOGGED_IN_USER_DATA:
            return {
                ...state,
                loggedInUserData: action.loggedInUserData
            }
        case SET_PORTAL_SETTINGS:
            return {
                ...state,
                portalSettings: action.portalSettings
            }
        case SET_AUTH_DATA:
            return {
                ...state,
                authData: action.authData
            }
    }
    return state;
}