import LocalizedStrings from 'react-localization';

const infoStrings = new LocalizedStrings({
    pl: {
        updateCompletedWithSuccess: "Zaktualizowano poprawnie.",
        autoLogoutMessage: "Nastąpiło wylogowanie z powodów bezpieczeństwa. Prosimy o ponowne zalogowanie się.",
        noRevisionRecordsFound: "Nie znaleziono rekordów.",
        cancelButton: "Anuluj",
        confirmButton: "Potwierdź",
        contentToolLoadingDescription: "Trwa ładowanie ekranu z narzędziami do modyfikowania zawartości bazy danych... ",
        dashboardLoadingDescription: "Trwa ładowanie głównego ekranu...",
        inboxLoadingDescription: "Trwa ładowanie skrzynki odbiorczej...",
        userManagementToolLoadingDescription: "Trwa ładowanie modułu zarządzania użytkownikami...",
        settingsToolLoadingDescription: "Trwa ładowanie ekranu ustawień...",
        accountSettingsTitle: "Ustawienia konta",
        logOutButton: "Wyloguj"
    },
    en: {
        updateCompletedWithSuccess: "Zaktualizowano poprawnie.",
        autoLogoutMessage: "Nastąpiło wylogowanie z powodów bezpieczeństwa. Prosimy o ponowne zalogowanie się.",
        noRevisionRecordsFound: "Nie znaleziono rekordów.",
        cancelButton: "Anuluj",
        confirmButton: "Potwierdź",
        contentToolLoadingDescription: "Trwa ładowanie ekranu z narzędziami do modyfikowania zawartości bazy danych... ",
        dashboardLoadingDescription: "Trwa ładowanie głównego ekranu...",
        inboxLoadingDescription: "Trwa ładowanie skrzynki odbiorczej...",
        userManagementToolLoadingDescription: "Trwa ładowanie modułu zarządzania użytkownikami...",
        settingsToolLoadingDescription: "Trwa ładowanie ekranu ustawień...",
        accountSettingsTitle: "Ustawienia konta",
        logOutButton: "Wyloguj"
    }
});

export default infoStrings;