import { Base64 } from 'js-base64';

import persistedState from '../../config/configureStore'

import {toast} from 'react-toastify';

import UserFetcher from '../../WebService/UserFetcher'

import {setLoggedInUserData, setAuthData} from '../../reducers/SystemData'

class Authentication {
    constructor() {
        this.authEndpoint = process.env.REACT_APP_BASE_HOST_URL + "/oauth";
        this.userFetcher = new UserFetcher((_) => {}, () => {
            toast.error("Błąd pobierania informacji o użytkowniku.");
        });
    }

    login(username, password, callback, errorCallback) {
        this.sendAuthenticationCall(username, password, errorCallback, () => {
            callback();
        });
    }

    logout(callback) {
        this.revokeToken(() => {
            localStorage.clear();
            callback();
        }); 
    }

    isAuthenticated() {
        let state = persistedState.getState().SystemData;
        return (state.authData['access_token'] !== null 
        & state.authData['access_token'] !== undefined 
        & state.authData['refresh_token'] !== null
        & state.authData['refresh_token'] !== undefined);
    }

    revokeToken(callback) {
        fetch(this.authEndpoint + '/revoke', {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + persistedState.getState().SystemData.authData['access_token']
                }
            })
            .then(_ => {
                callback();
            })
            .catch(error => {
                console.log(error);
            });
    }

    checkToken(token, callback) {
        const formData = new FormData();
        formData.append('token', token);
        fetch(this.authEndpoint + '/check_token', {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': 'Basic ' + Base64.encode("bo-core:public")
            }
        })
        .then(response => response.json())
        .then(response => {
            callback(response.active !== undefined && response.active === true);
        })
        .catch(error => {
            console.log(error);
        });
    }

    sendAuthenticationCall(username, password, errorHandler, successHandler) {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);
        formData.append('grant_type', 'password');
        fetch(this.authEndpoint + '/token', {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': 'Basic ' + Base64.encode("bo-core:public")
                }
            })
            .then(response => response.json())
            .then(authInfo => {
                if (authInfo.access_token === undefined || authInfo.refresh_token === undefined) {
                    errorHandler(this.prepareErrorMessage(authInfo));
                } else {
                    this.saveTokensToLocalStorage(authInfo);
                    setTimeout(() => {
                        this.fetchCurrentUserInfo(() => {
                            successHandler(authInfo)
                        });
                    }, 500);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    fetchCurrentUserInfo(successHandler) {
        this.userFetcher.fetchCurrentUserInfo((currentUserInfo) => {
            this.saveCurrentUserInfoToLocalStorage(currentUserInfo);
            successHandler();
        });
    }

    prepareErrorMessage(authInfo) {
        if (authInfo.error_description !== undefined && authInfo.error_description.includes("Bad credentials")) {
            return "Wprowadzono nieprawidłowe dane logowania";
        } else if (authInfo.error_description !== undefined && authInfo.error_description.includes("User not found")) {
            return "Wprowadzono nieprawidłowy login lub hasło";
        } else {
            return "Wystąpił błąd logowania";
        }
    }

    saveTokensToLocalStorage(fetchedAuthInfo) {
        if (fetchedAuthInfo !== undefined 
            && fetchedAuthInfo.access_token !== undefined
            && fetchedAuthInfo.refresh_token !== undefined) {
            persistedState.dispatch(setAuthData(fetchedAuthInfo));
            localStorage.setItem('access_token', fetchedAuthInfo.access_token);
            localStorage.setItem('refresh_token', fetchedAuthInfo.refresh_token);
        }
    }
    
    saveCurrentUserInfoToLocalStorage(currentUserInfo) {
        if (currentUserInfo !== undefined) {
            persistedState.dispatch(setLoggedInUserData(currentUserInfo));
        }
    }
}

export default new Authentication();