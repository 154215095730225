import LocalizedStrings from 'react-localization';

const loginStrings = new LocalizedStrings({
    pl: {
        welcomeMessage: "Witamy!",
        passwordPlaceholder: "Hasło",
        loginButton: "Zaloguj",
        resetPasswordMessage: "Wpisz nowe hasło:",
        resetPasswordButton: "Dalej"
    },
    en: {
        welcomeMessage: "Witamy!",
        passwordPlaceholder: "Hasło",
        loginButton: "Zaloguj",
        resetPasswordMessage: "Wpisz nowe hasło:",
        resetPasswordButton: "Dalej"
    }
});

export default loginStrings;