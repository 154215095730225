import persistedState from '../config/configureStore'

export default class UserUpdater {
    constructor(successHandler, failureHandler) {
        this.successHandler = successHandler;
        this.failureHandler = failureHandler;
        this.userEndpoint = process.env.REACT_APP_BASE_HOST_URL + "/users";
    }

    updateUser(user) {
        fetch(this.userEndpoint + '/' + user.id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + persistedState.getState().SystemData.authData['access_token']
                },
                body: JSON.stringify(this.prepareUserForUpdating(user))
            })
            .then(response => {
                if (!response.ok) {
                    this.failureHandler();
                } else {
                    this.successHandler();
                }
            })
            .catch(error => {
                this.failureHandler();
                console.log(error);
            });
    }

    createUser(user) {
        fetch(this.userEndpoint, {
            method: 'POST',
            body: JSON.stringify(this.prepareUserForCreating(user)),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + persistedState.getState().SystemData.authData['access_token']
            }
        })
        .then(response => {
            if (!response.ok) {
                this.failureHandler();
            } else {
                this.successHandler();
            }
        })
        .catch(error => {
            this.failureHandler();
            console.log(error);
        });
    }

    deleteUser(userId) {
        fetch(this.userEndpoint + "/" + userId, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + persistedState.getState().SystemData.authData['access_token']
                }
            })
            .then(response => {
                if (!response.ok) {
                    this.failureHandler();
                } else {
                    this.successHandler();
                }
            })
            .catch(error => {
                this.failureHandler();
                console.log(error);
            });
    }

    changePassword(userId, password, callback) {
        fetch(this.userEndpoint + '/password/' + userId, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + persistedState.getState().SystemData.authData['access_token']
            },
            body: password
        })
        .then(response => {
            if (response.ok) {
                callback();
            }
        })
        .catch(error => {
            this.failureHandler();
            console.log(error);
        });
    }

    changeOwnPassword(password, callback) {
        fetch(this.userEndpoint + '/password', {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + persistedState.getState().SystemData.authData['access_token']
                },
                body: password
            })
            .then(response => {
                if (response.ok) {
                    callback();
                }
            })
            .catch(error => {
                this.failureHandler();
                console.log(error);
            });
    }

    changeResetPasswordStatus(status, callback) {
        fetch(this.userEndpoint + '/reset', {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + persistedState.getState().SystemData.authData['access_token'],
                'Content-Type': 'application/json'
            },
            body: status
        })
        .then(response => {
            if (response.ok) {
                callback();
            }
        })
        .catch(error => {
            this.failureHandler();
            console.log(error);
        });
    }

    prepareUserForUpdating(user) {
        delete user.id;
        return user;
    }

    prepareUserForCreating(user) {
        delete user.id;
        user.shouldResetPassword = true;
        user.password = "1234ZMIANA";
        return user;
    }
}